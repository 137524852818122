<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
      {{$t('drivers.addDriver')}}
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(addDriver)">
      <b-row>
        <b-col md="8">
          <div>
            <b-card class="iq-border-radius-10 mb-3">
              <template v-slot:header>
                <h5 class="text-primary"><i class="las la-file-alt"></i> معلومات السائق</h5>
              </template>
              <b-row>
                <b-col  cols="4">
                  <input-form
                      class="mb-3"
                      v-model="driver.name"
                      validate="required"
                      :name="$t('drivers.driverName')"
                      :disabled="routeName === 'showDriver'"
                      :placeholder="$t('drivers.driverName')"
                      :label="$t('drivers.driverName')"
                  />
                </b-col>
                <b-col  md="4">
                  <div class="d-flex mb-3">
                    <input-form
                        class="mb-3 flex-1 flex-grow-1 phone-input"
                        v-model="driver.phone"
                        :validate="'required|numeric'"
                        :name="$t('auth.phoneNumber')"
                        :disabled="routeName === 'showDriver'"
                        :placeholder="$t('auth.phoneNumber')"
                        :label="$t('auth.phoneNumber')"
                    />
                    <vue-country-code
                        v-model="driver.countryCode"
                        @onSelect="onSelect"
                        :onlyCountries="['sa', 'eg']"
                        :dropdownOptions="{ disabledDialCode: true }"
                        :enabledCountryCode= true
                        defaultCountry="sa"
                        class="code-input"
                    />
                  </div>
                </b-col>
                <b-col  md="4">
                  <input-form
                      class="mb-3"
                      v-model="driver.idNumber"
                      :validate="{ required: true, numeric:true, digits:10,  regex: /^[1-2][0-9]*$/}"
                      :name="$t('auth.IdNumber')"
                      :disabled="routeName === 'showDriver'"
                      :placeholder="$t('auth.IdNumber')"
                      :label="$t('auth.IdNumber')"
                  />
                </b-col>
                <b-col  md="4">
                  <label class=" font-size-14">تاريخ اصدار الرخصة </label>
                  <flat-picker  v-model="driver.idStartDate" class="form-control form-date"  validate="required" :name="`تاريخ النقل`"  :disabled="routeName === 'showDriver'"  placeholder="select date"></flat-picker>
                </b-col>
                <b-col  md="4">
                  <label class=" font-size-14">تاريخ انتهاء الرخصة </label>
                  <flat-picker  v-model="driver.idExpirationDate" class="form-control form-date"  validate="required" :name="`تاريخ النقل`" :disabled="routeName === 'showDriver'" :config="{minDate: 'today'}" placeholder="select date"></flat-picker>
<!--                  <input-form-->
<!--                      class="mb-3 joining-label"-->
<!--                      v-model="driver.idEndDate"-->
<!--                      :validate="'required|numeric'"-->
<!--                      name="id"-->
<!--                      placeholder="" label="تاريخ أنتهاء الهوية"-->
<!--                  />-->
                </b-col>
                <b-col  md="4">
                  <input-form
                      class="mb-3"
                      v-model="driver.email"
                      :validate="'required'"
                      :name="$t('auth.email')"
                      :disabled="routeName === 'showDriver'"
                      :placeholder="$t('auth.email')"
                      :label="$t('auth.email')"
                      type="email"
                  />
                </b-col>
                <b-col  md="8">
                  <input-form
                      class="mb-3"
                      v-model="driver.address"
                      :validate="'required'"
                      :name="$t('auth.address')"
                      :disabled="routeName === 'showDriver'"
                      :placeholder="$t('auth.address')"
                      :label="$t('auth.address')"
                  />
                </b-col>
              </b-row>
            </b-card>
            <b-card class="iq-border-radius-10 mb-3">
              <template v-slot:header>
                <h5 class="text-primary"><i class="las la-image"></i>  الصور</h5>
              </template>
              <b-row>
                <b-col md="12" class="mb-4" v-for="(doc, key) in requiredDocuments" :key="key">
                  <cropper-images
                      :label="doc.name"
                      nameOfImage="image.jpg"
                      @cropper-save="saveGalleryImage"
                      :progressLoading="loadingGallery"
                      :multi="false"
                      :imageUrl="driver.driverImages"
                  />
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-col>
        <b-col md="3">
          <b-card class="iq-border-radius-10 mb-3 main-actions">
            <template v-slot:header>
              <h5 class="text-primary">
                <i class="las la-save"></i>
                النشر
              </h5>
            </template>
            <div v-if="loadingSubmitForm">
              <b-button variant="primary" class="w-100" disabled>
                <spinner-loading text="يتم الحفظ"></spinner-loading>
              </b-button>
            </div>
            <div class="d-flex justify-content-end gap_1" v-else>
              <b-button variant="primary"  class="w-100" type="submit" > حفظ </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
// import settingsServices from '../services/settings'
import mainInfoMixins from '@/mixins/mainInfo'
import { core } from '@/config/pluginInit'
import transportationServices from '@/modules/Company/drivers/services/drivers'
export default {
  name: 'addDriver',
  mixins: [mainInfoMixins],
  data () {
    return {
      requiredDocuments: null,
      routeName: this.$route.name,
      driver: {
        name: '',
        phone: '',
        idNumber: '',
        idStartDate: '',
        idExpirationDate: '',
        email: '',
        address: '',
        driverImages: '',
        status: 'processing',
        countryCode: ''
      },
      loadingSubmitForm: false,
      loadingGallery: 0,
      removeLoadingUi: false
    }
  },
  methods: {
    onSelect ({ name, dialCode }) {
      const countryCode = {
        20: '002',
        966: '00966'
      }
      this.driver.countryCode = countryCode[dialCode]
    },
    getRequiredDocuments () {
      transportationServices.getRequirementsDocuments().then(response => {
        this.requiredDocuments = response.data.data
      })
    },

    getDriverData () {
      transportationServices.getDriver(this.$route.params.id).then(res => {
        this.driver.name = res.data.data.name
        this.driver.phone = res.data.data.phone
        this.driver.idNumber = res.data.data.idNumber
        this.driver.address = res.data.data.address
        this.driver.email = res.data.data.email
        this.driver.idStartDate = res.data.data.idStartDate
        this.driver.idExpirationDate = res.data.data.idExpirationDate
        this.driver.driverImages = res.data.data.driverImages.map(item => item.id)
        // this.product.faqs = res.data.faqs ? res.data.faqs : []
      })
    },

    saveGalleryImage (file) {
      this.removeLoadingUi = false
      const formData = new FormData()
      formData.append('directory', 'DriverImages')
      formData.append('image_name', file.imageInfo.name)
      formData.append('file', file.image)
      formData.append('app_name', 'transportation')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingGallery = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.driver.driverImages = res.data
        this.showSuccessUploadFile()
        this.removeLoadingUi = true
      })
    },

    addDriver () {
      this.loadingSubmitForm = true

      // if (this.code === '00966' && this.driver.phone.charAt(0) === '0') {
      //   this.fullNumber = this.driver.phone.slice(1)
      // } else {
      //   this.fullNumber = this.driver.phone
      // }

      if (this.$route.name === 'editDriver') {
        this.editDriver()
        this.loadingSubmitForm = true
      } else {
        transportationServices.addDriver(this.driver).then(res => {
          core.showSnackbar('success', res.data.message)
        }).finally(() => {
          this.loadingSubmitForm = false
        })
      }
    },

    editDriver () {
      transportationServices.editDriver(this.$route.params.id, this.driver).then(() => {
        core.showSnackbar('success', 'تم تعديل بنجاح')
        // this.$router.push({ name: 'vehiclesType' })
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getRequiredDocuments()
    if (this.$route.params.id) {
      this.getDriverData()
    }
  }
}
</script>
<style>
.phone-input .form-control{
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.code-input{
  margin-top: 34px;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 9px !important;
  border-top-left-radius: 9px !important;
}
.main-actions {
  position: sticky !important;
  top: 0px;
}
.deleteLabelButton {
  position: absolute;
  left: 10px;
  top: -30px;
}
</style>
